import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token") || null,
  profile: JSON.parse(localStorage.getItem("userDetail")) || null,
  settings:{},
  menu:[]
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.token = action.payload.data.data.token;
      state.profile = action.payload.data.data;
    },
    setLogout: (state) => {
      state.token = null;
    },
    setSettings: (state,action) => {
      state.settings = action.payload;
    },
    setMenu: (state,action) => {
      state.menu = action.payload;
    },
  },
});
export const { setLogin, setLogout,setSettings,setMenu } = authSlice.actions;
export default authSlice.reducer;
