import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadcumData: { title: "" },
};

export const breadcumSlice = createSlice({
  name: "breadcum",
  initialState,
  reducers: {
    setBreadcum: (state, action) => {
      state.breadcumData = action.payload;
    },
  },
});
export const { setBreadcum } = breadcumSlice.actions;
export default breadcumSlice.reducer;
