import { CSSProperties } from "react";
import { PuffLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const FallbackSpinner = () => {
  return (
    <div
      className="spinner"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PuffLoader
        color="#36d7b7"
        height={28}
        loading
        margin={20}
        speedMultiplier={1}
      />
    </div>
  );
};

export default FallbackSpinner;
