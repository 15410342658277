import React, { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
// import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import ScrollToTopOnPageChange from './ScrollToTopOnPageChange';
import { store } from "./redux/store";
import Spinner from "./components/spinner/Fallback-Spinner";
import "react-loading-skeleton/dist/skeleton.css";

const LazyApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  <ScrollToTopOnPageChange />
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        <Toaster
          position={"top-right"}
          toastOptions={{ className: "react-hot-toast" }}
        />
      </Suspense>
    </Provider>
  </BrowserRouter>
);
